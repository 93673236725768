import React from 'react'
import loadable from '@loadable/component';
import Layout from '../components/layout'
import NewsLetterModule from '../components/NewsLetterModule/NewsLetterModule'
import StickyButtons from '../components/StickyButtons/StickyButtons'
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import { PageLinks } from '../common/site/page-static-links';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const CareerDetailIntro = loadable(() => import("../components/CareerDetailIntro/CareerDetailIntro"));
const CareerDetailDesc = loadable(() => import("../components/CareerDetailDesc/CareerDetailDesc"));

const CareerDetailTemplate = ({ data }) => {
    const PageData = data?.strapiCareer
    let breadcrumData;

    if (PageLinks?.career_parent_label) {
        breadcrumData = { parentlabel: PageLinks.career_parent_label, parentlink: PageLinks.career_parent, subparentlabel: PageLinks.career_label, subparentlink: PageLinks.career, title: PageData.title }
    } else {
        breadcrumData = { parentlabel: PageLinks.career_label, parentlink: PageLinks.career, title: PageData.title }
    }


    return (
        <Layout footerCta="footercta" popularSearch={"Generic pages"}>
            <div className="layout-padding-top">
                <BreadcrumbModule {...breadcrumData} />

                <CareerDetailIntro
                    title={PageData.title}
                    location={PageData.location}
                    salary={PageData.salary}
                    tags={PageData.category}
                    crm_id={PageData.crm_id}
                />

                <CareerDetailDesc
                    title={PageData.title}
                    crm_id={PageData.crm_id}
                    location={PageData.location}
                    content={PageData?.job_details?.data?.job_details}
                    contact={PageData?.contact}
                />

                <NewsLetterModule />
            </div>

            <StickyButtons tag="careers-detail-page" careerData={PageData} />
        </Layout>
    )
}


export const Head = ({ data }) => {
    const PageData = data?.strapiCareer
    const metaDescription = `Join our dynamic team as a ${PageData.title} at ${process.env.GATSBY_SITE_NAME}. Take the first step towards a rewarding real estate career with our established agency. Apply now!`

    return (
        <Seo title={PageData.title} description={metaDescription} />
    )
}

export default CareerDetailTemplate

export const query = graphql`
query ($page_id: Int) {
    strapiCareer(strapi_id: {eq: $page_id}) {
        ...CareerFragment
        job_details {
            data {
                job_details
            }
        }
        image {
            alternativeText
            url
        }
        imagetransforms {
            image_Transforms
        }
        contact {
            ...TeamFragment
        }
    }
}`